@import '~antd/dist/antd.css';
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,html{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: #fff;
  
}

a{
  text-decoration: none !important;
}

.container{
  width: 100%;
  background: #fff;
  display: flex;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 50px;
}

.app-nav{
  width: 100%;
  height: 90px;
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: 10;
  
}
.logo{
  /*width: 180px;*/
  /*height: 52px;*/
  border-radius: 5px;
  /*background: #FF9900;*/
  /*margin: 6px 15px 0px 15px;*/
  /*padding: 0px 1px 0px 1px;*/
  float: left;
  text-align: center;
  font-family: Sans-Serif;
  font-weight: bold;
  font-size: 130%;
}

.content{
  position: fixed;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  top: auto;
  left: 0;
  z-index: 9;
  
}
.content-wrapper{
  height: 100%;
  width: 100%;
  padding: 10px;
  background: #fff;
  padding-top: 90px;
  padding-right: 50px;
  padding-left: 60px;
  overflow-y: scroll;
  overflow-x: scroll;
}

.err{
  border-color: red !important;
}

.teamImages {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
}

.label-top {
    transform: translateY(0px);
}

.label {
    transform: translateY(0px);
}


/*.logoutStyle {*/
/*  margin: 50px;*/
/*  padding: 50px;*/
  
/*}*/